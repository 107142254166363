import React from 'react'
import publications from '../Components/Assets/publications';
import Item from "../Components/Item"
import Hero from '../Components/Hero/Hero2'
import Navbar from "../../src/Components/Navbar/Navbar"



export const Publications = () => {
  return (<>
  <Navbar/>
    <div className="container">
      <Hero title="Relevant Publications" />
      <br /><br />
      <div className="row row-cols-1 row-cols-md-5 g-4">

        {publications.map((item, i) => {
          return <Item key={i} id={item.id} name={item.name} file_size={item.file_size} upload_date={item.upload_date} location={item.location} />
        })}

      </div>
    </div>
    </>
  )
}
export default Publications;

