import React, { useState } from 'react'
import akenaLogo from '../Assets/akplus_logo_trans_bg.png'
import tdLogo from '../Assets/td_logo.png'
import { Link } from "react-router-dom"

export const NavbarHome = () => {
  const [menu, setMenu] = useState("home")

  

  return (

    <nav>
      <ul id="menu-links">
        <li><img src={akenaLogo} alt="" height={60} /></li>
        <li><img src={tdLogo} alt="" height={60} /></li>
        <li onClick={() => { setMenu("home") }}><Link style={{ textDecoration: 'inherit', color: 'black', fontFamily: '"Syne", sans-serif' }} to="/">Home</Link></li>
        <li onClick={() => { setMenu("dash") }}><Link style={{ textDecoration: 'inherit', color: 'black', fontFamily: '"Syne", sans-serif' }} to="/dashboard">Dashboard</Link></li>
        <li onClick={() => { setMenu("dash") }}><Link style={{ textDecoration: 'inherit', color: 'black', fontFamily: '"Syne", sans-serif' }} to="/data">Data</Link></li>
        <li onClick={() => { setMenu("pubs") }}><Link style={{ textDecoration: 'inherit', color: 'black', fontFamily: '"Syne", sans-serif' }} to="/publications">Publications</Link></li>
        <li onClick={() => { setMenu("faqs") }}><Link style={{ textDecoration: 'inherit', color: 'black', fontFamily: '"Syne", sans-serif' }} to="/faqs">Faqs</Link></li>
      </ul>
      <button className="nav-btn" id="confeti">
        See All Dashboards <img src="arrow-white.png" alt="" />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </button>
      <img
        src="menu.png"
        alt=""
        className="menu-icon"
        onclick="toggleMenu()"
      />
    </nav>





  )
 

}

export default NavbarHome
