let publications = [
    {
        id:1,
        name:"Kaduna State General HH survey 2020",
        file_size:"--",
        upload_date:"1/20/2024",
        location: "https://ghs.kdbs.ng/home"
      },

  ];
  
  export default publications;
  