import React, { useState } from 'react'
import akenaLogo from '../Assets/akplus_logo_trans_bg.png'
import tdLogo from '../Assets/td_logo.png'
import { Link } from "react-router-dom"

export const Navbar = () => {
  const [menu, setMenu] = useState("home")

  

  return (

   


    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img
            src={akenaLogo}
            alt="akena logo"

            height={50}
          />
          <img
            style={{ marginLeft: 20 }}
            src={tdLogo}
            alt="td logo"

            height={50}
          />

        </a>
        <ul className="navbar-nav">
          <li className="nav-item" onClick={() => { setMenu("home") }}>
            <a className={menu === 'home' ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/">Home</Link>
            </a>
          </li>

          <li className="nav-item" onClick={() => { setMenu("dash") }}>
            <a
              className={menu === 'dash' ? 'nav-link active' : 'nav-link'}
              href="#"
              tabIndex={-1}
              aria-disabled="true"
            >
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/dashboard">Dashboard</Link>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={menu === 'data' ? 'nav-link active' : 'nav-link'}
              href="#"
              tabIndex={-1}
              aria-disabled="true"
            >
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/data">Data</Link>
            </a>
          </li>

          <li className="nav-item">
            <a
              className={menu === 'pubs' ? 'nav-link active' : 'nav-link'}
              href="#"
              tabIndex={-1}
              aria-disabled="true"
            >
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/publications">Publications</Link>
            </a>
          </li>

          <li className="nav-item">
            <a
              className={menu === 'faqs' ? 'nav-link active' : 'nav-link'}
              href="#"
              tabIndex={-1}
              aria-disabled="true"
            >
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/faqs">FAQs</Link>
            </a>
          </li>
        </ul>


      </div>
    </nav>

  )
 

}

export default Navbar
