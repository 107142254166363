import React from 'react'
import '../../src/Style.css'
import Navbar from "../../src/Components/Navbar/NavbarHome"

// import Hero from '../Components/Hero/Hero'

export const Home = () => {
  return (


        <>
      <Navbar/>

 
  {/* <nav>
    <ul id="menu-links">
      <li>Home</li>
      <li>Dashboard</li>
      <li>Data</li>
      <li>Publications</li>
      <li>Faqs</li>
    </ul>
    <button className="nav-btn" id="confeti">
      See All Dashboards <img src="arrow-white.png" alt="" />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </button>
    <img
      src="images/menu.png"
      alt=""
      className="menu-icon"
      onclick="toggleMenu()"
    />
  </nav> */}
  <div className="header">
    <div className="header-content">
      <h1>
        Akena Health
        <br />
        <b>
          Data
          <br />
          Hub
        </b>
      </h1>
      <div className="btn-container">
        <button className="btn-dark">
          <h4>Go to Latest Dashboard</h4>
          <img src="arrow-black.png" alt=""/>
        </button>
        {/* <button class="btn-light">Know more about Akena Data Hub<img src="images/arrow-white.png" alt=""></button> */}
      </div>
      <p>
        <span id="l-1">
          The Latest Dashboard enables you explore our interactive dashboard
          showcasing{" "}
        </span>
        <br />
        <span id="l-2">
          1000 days of Reproductive, Maternal, Newborn, and Child Health data.
        </span>
        <br />
        <span id="l-3">
          Uncover insights, drive change, and join us in creating a
        </span>
        <br />
        <span id="l-4">healthier future for all. Dive in now!</span>
      </p>
    </div>
    <img src="  user3.png" alt="" className="user-img" />
  </div>
</>

    // </div>
  )
}
export default Home;

