let downloadables = [
    {
      id:1,
      name:"All RMNCH indicators",
      file_size:"26KB",
      upload_date:"1/20/2024",
      location:"https://akenaresearch.com/all_rmnch_indicators.xlsx"
    },
  

  ];
  
  export default downloadables;
  