
import React, { Component, useEffect, useRef, useState } from 'react';
import Hero from '../Components/Hero/Hero2'
import Mortality from "./Mortality"
import Navbar from "../../src/Components/Navbar/Navbar"


const Dashboard = () => {
  const [mortality, setMortality] = useState("visible")
  const [fertility, setFertility] = useState("hidden")
  const [antenatal, setAntenatal] = useState("hidden")
  const [delivery, setDelivery] = useState("hidden")
  const [immunization, setImmunization] = useState("hidden")
  const [nutrition, setNutrition] = useState("hidden")
  const [malaria, setMalaria] = useState("hidden")
  const [social, setSocial] = useState("hidden")


  //all dictionaries

  const dashboard_links = {
    mortality: 'https://public.tableau.com/views/mortality_indicators_ng/Dashboard1',
    fertility: 'https://public.tableau.com/views/fertility_indicators_ng/Dashboard2',
    antenatal: 'https://public.tableau.com/views/antenatal_indicators_ng/Dashboard3',
    delivery: 'https://public.tableau.com/views/delivery_indicators_ng/Dashboard4',
    immunization: 'https://public.tableau.com/views/immunization_indicators_ng/Dashboard5',
    nutrition: 'https://public.tableau.com/views/nutrition_indicators_ng/Dashboard6',
    malaria: 'https://public.tableau.com/views/malaria_indicators_ng/Dashboard7',
    social: 'https://public.tableau.com/views/social_determinants_indicators_ng/Dashboard8',
  };

  return (<>
    <Navbar/>
    <div className="container-fluid">
      <Hero title="RMNCH 1000 Days Dashboard" />
      <div className="row">
        <div className="col-1">
          <h4 className="text-secondary">Indicator Classes</h4>
          {/* mortality */}
          <a class="btn btn-secondary btn-sm" onClick={() => {
            setMortality("visible");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("hidden");


          }} style={{ margin: 5 }} href="#" role="button">Mortality Indicators</a><br />

{/* fertility */}
          <a class="btn btn-secondary btn-sm" 
          onClick={() => {
            setMortality("hidden");
            setFertility("visible");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("hidden");



          }}
          style={{ margin: 5 }} href="#" role="button">Fertility FP Indicators</a><br />

{/* antenatal */}
          <a class="btn btn-secondary btn-sm" 
          onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("visible");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("hidden");



          }}
          style={{ margin: 5 }} href="#" role="button">Antenatal Care Indicators</a><br />
          <a class="btn btn-secondary btn-sm" 
          onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("visible");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("hidden");



          }}
          
          style={{ margin: 5 }} href="#" role="button">Delivery Indicators</a><br />
          <a class="btn btn-secondary btn-sm" 
          onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("visible");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("hidden");



          }}
          style={{ margin: 5 }} href="#" role="button">Immunization Indicators</a><br />
          <a class="btn btn-secondary btn-sm" 
           onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("visible");
            setMalaria("hidden");
            setSocial("hidden");



          }}
          style={{ margin: 5 }} href="#" role="button">Nutrition Indicators</a><br />
          <a class="btn btn-secondary btn-sm" 
           onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("visible");
            setSocial("hidden");



          }}
          style={{ margin: 5 }} href="#" role="button">Malaria Indicators</a><br />
          <a class="btn btn-secondary btn-sm"
          onClick={() => {
            setMortality("hidden");
            setFertility("hidden");
            setAntenatal("hidden");
            setDelivery("hidden");
            setImmunization("hidden");
            setNutrition("hidden");
            setMalaria("hidden");
            setSocial("visible");



          }}
          
          style={{ margin: 5 }} href="#" role="button">Social Determinants</a><br />



        </div>

        <div className="col-11" >

          {mortality === 'visible' ? <Mortality url= {dashboard_links.mortality} /> : ''}
          {fertility === 'visible' ? <Mortality url={dashboard_links.fertility} /> : ''}
          {antenatal === 'visible' ? <Mortality url={dashboard_links.antenatal} /> : ''}
          {delivery === 'visible' ? <Mortality url={dashboard_links.delivery} /> : ''}
          {immunization === 'visible' ? <Mortality url={dashboard_links.immunization} /> : ''}
          {nutrition === 'visible' ? <Mortality url={dashboard_links.nutrition} /> : ''}
          {malaria === 'visible' ? <Mortality url={dashboard_links.malaria} /> : ''}
          {social === 'visible' ? <Mortality url={dashboard_links.social} /> : ''}


        </div>

      </div>

    </div>
</>
  )
}

export default Dashboard
