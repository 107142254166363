import React from 'react'

export const Hero2 = (props) => {
  return (
    <div>
  <div
    className="p-5 text-center bg-image rounded-3"
    style={{
      backgroundImage:
        'url("jumbotron_purple.jpg")',
      height: 150,backgroundSize: 'cover'
    }}
  >
    <div className="mask rounded-3" style={{ backgroundColor: "rgba(0, 18, 71, 0.6)"}}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-white">
          <h2 className="mb-3">{props.title}</h2>
          
        </div>
      </div>
    </div>
  </div>

    </div>
  )
}

export default Hero2
