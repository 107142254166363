
import React, { Component, useEffect, useRef,useState } from 'react';
import Hero from '../Components/Hero/Hero2'



function Mortality(props) {
  const { tableau } = window;
  const url = props.url;
  const ref = useRef(null);
  let chartInstance;

  function initViz() {
    chartInstance = new tableau.Viz(ref.current, url,{
      height: "100vh",
      width:"100%",
    });
  }

  useEffect(() => {
    initViz();
    return () => {
      if (chartInstance) {
        chartInstance.dispose();
      }
    }
  }, [])

  return (
    
      
     <div className="container-fluid" ref={ref}></div>
      


  )



}

export default Mortality;
