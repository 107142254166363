import React from 'react'
import Hero from '../Components/Hero/Hero2'
import Navbar from "../../src/Components/Navbar/Navbar"


export const Faqs = () => {
  return (<>
  <Navbar/>
    <div className="container">
      <Hero title="Frequently Asked Questions"/>
      <p className="text-secondary text-center" style={{marginTop: "50px"}}>Nothing Yet</p>
    </div>
    </>

  )
}
export default Faqs;

