import './App.css';
import Navbar from "./Components/Navbar/Navbar"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from "./Pages/Home"
import Dashboard from "./Pages/Dashboard"
import Data from "./Pages/Data"
import Publications from "./Pages/Publications" 
import Faqs from "./Pages/Faqs"
function App() {
  return (
    <div>
      <BrowserRouter>
      {/* <Navbar/> */}


      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/data" element={<Data/>}/>
        <Route path="/publications" element={<Publications/>}/>
        <Route path="/faqs" element={<Faqs/>}/>
        
      </Routes>
      </BrowserRouter>
      

     </div>
  );
}

export default App;
