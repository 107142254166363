import React from 'react'
import download_icon from "./Assets/download.jpg"


const Item = (props) => {
  return (<>
    <div className="col">
          <div className="card">
            <img
              src={download_icon}
              className="card-img-top"
              alt="Hollywood Sign on The Hill"
            />
            <div className="card-body">
              <h5 className="card-title">{props.name}</h5>
              <p className="card-text">
                {props.upload_date}
              </p>
              <a class="btn btn-primary" href={props.location} role="button">Download [{props.file_size}]</a>
            </div>
          </div>
        </div>
  
  
  
  </>
  
  )
}

export default Item